/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// import { handleGaCustomEvent } from "../../utils/functions";
import { Link, useLocation } from "react-router-dom";
import Logo from "./partials/Logo";

const menuItems = [
  {
    name: "Product",
    subMenu: {
      product: {
        heading: "Product",
        values: [
          {
            heading: "OLake",
            detail: "",
            color: "#6061F6",
            innerLink: "/olake",
            outerLink: null,
          },
          {
            heading: "One Stack",
            detail: "Full feature data platform",
            color: "#099BEC",
            innerLink: "/onestack-data",
            outerLink: null,
          },
        ],
      },
    },
  },
  {
    name: "Blog",
    subMenu: null,
    outerLink: "/blog",
    innerLink: null,
  },
];

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

// Common function to switch between different button sets
const HeaderButtons = (isOlakePage) => (
  <div className="header-btns">
    {/* <div className="olake-header"> */}
    {isOlakePage && (
      <a href="/olake#olake-form-product">
        <button className="olake-joinlist-button">Join Waitlist</button>
      </a>
    )}

    {/* </div> */}
  </div>
);

const GetProductLink = (item, closeMenu) => {
  if (item?.subMenu) {
    return <Link to={""}>{item?.name}</Link>;
  }
  if (item?.outerLink) {
    return (
      <a href={item?.outerLink} target="_blank" rel="noopener noreferrer">
        {item?.name}
      </a>
    );
  }
  if (item?.innerLink) {
    return (
      <Link to={item?.innerLink} onClick={closeMenu}>
        {item?.name}
      </Link>
    );
  }
};

const GetSubItem = (value) => {
  return (
    <div className="item">
      <div
        className="box color-a"
        style={{ background: `${value.color}` }}
      ></div>
      <div className="product-details">
        <span className={`${value.detail ? "name" : "name-1"}`}>
          {value.heading}
        </span>
        {value?.detail && <span className="detail">{value?.detail}</span>}
      </div>
    </div>
  );
};

const FeatureBox = ({ item }) => {
  // const [selected, setSelected] = useState(0);
  return (
    <div className="product-box feature-box">
      <div className="heading" style={{ color: " #FFBB24" }}>
        {item?.feature?.heading}
      </div>
      {/* <div className="product-tabs">
        {item?.feature.tabs.map((value, index) => (
          <div
            className={`product-tab ${index === selected ? "selected" : ""}`}
            key={index}
            onClick={() => setSelected(index)}
          >
            {value.heading}
          </div>
        ))}
      </div> */}
      {item?.feature?.tabs[0]?.value?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item?.outerLink ? (
              <a
                href={item?.outerLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {GetSubItem(item)}
              </a>
            ) : (
              <Link to={item?.innerLink}>{GetSubItem(item)}</Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const RenderSubMenu = (item) => {
  return (
    <div className="dropdown-wrapper">
      <div className="header-dropdown">
        {item.product && (
          <div className="product-box">
            {item.product?.heading && <div className="heading">Product</div>}
            {item.product?.values?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {value?.outerLink ? (
                    <a
                      href={value?.outerLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {GetSubItem(value)}
                    </a>
                  ) : (
                    <Link to={value?.innerLink}>{GetSubItem(value)}</Link>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}

        {item?.feature && <FeatureBox item={item} />}
      </div>
    </div>
  );
};

const NewHeader = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);
  const { pathname } = useLocation();
  const isOlakePage = pathname === "/olake";

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    document.body.classList.add("no-scroll");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    document.body.classList.remove("no-scroll");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };
  const classes = classNames(
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={`${classes} absolute z-index`}>
      <div className="container header-container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
          style={{ flexWrap: "wrap" }}
        >
          <Logo header />

          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner" style={{ flexWrap: "wrap" }}>
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                      // isHomePage && "home-header"
                    )}
                  >
                    {menuItems.map((item, index) => {
                      return (
                        <li key={index} className={"nav-link-secondary"}>
                          {GetProductLink(item, closeMenu)}
                          {item?.subMenu && (
                            <svg
                              className="invert-chevron"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.1663 6L8.49967 10.6667L3.83301 6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {item?.subMenu && RenderSubMenu(item.subMenu)}
                        </li>
                      );
                    })}

                    {/* <li>
                      <a
                        href="https://datatribe.datazip.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleGaCustomEvent(
                            "external clicks",
                            "community click"
                          );
                        }}
                      >
                        Community
                      </a>
                    </li> */}
                    {/* <li>
                      <a onClick={(e) => e.preventDefault()}>
                        <button
                          className={classNames(
                            "button__main",
                            "button__signup",
                            !isHomePage && "button__signup-secondary"
                          )}
                          onClick={() => history.push("/onestack-data/sign-up")}
                        >
                          Sign Up
                        </button>
                      </a>
                    </li> */}
                  </ul>
                  {HeaderButtons(isOlakePage)}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

NewHeader.propTypes = propTypes;
NewHeader.defaultProps = defaultProps;

export default NewHeader;
