import React, { useEffect, useRef } from "react";
import ScrollReveal from "../../utils/ScrollReveal";

const features = [
  {
    heading: "Iceberg as a Lakehouse format",
    subheading:
      "Avoid vendor lock-in and query from any warehouse/query engine",
    image: require("../../assets/images/olake-product-1.svg"),
  },
  {
    heading: "Monitoring alerts & error handling",
    subheading:
      "Monitoring alert for schema changes, backup tables and columns for error handling in strict schema ",
    image: require("../../assets/images/olake-product-2.svg"),
  },
  {
    heading: "Real-time replication",
    subheading: "CDC based approach for data ingestion",
    image: require("../../assets/images/olake-product-3.svg"),
  },
  {
    heading: "Schema discovery and selection",
    subheading:
      "Automatic identification of object keys and arrays, generating schema representations ",
    image: require("../../assets/images/olake-product-4.svg"),
  },
  {
    heading: "Parallel Initial Load",
    subheading:
      "Define parallelism for initial sync times from days to minutes",
    image: require("../../assets/images/olake-product-5.svg"),
  },
  {
    heading: "Auto flattened table population",
    subheading:
      "To convert semi structured objects into relational flat tables and separate exploded tables for array type objects.",
    image: require("../../assets/images/olake-product-6.svg"),
  },
  {
    heading: "Data Quality at scale",
    subheading:
      "Manage changing data types (polymorphic data) and schema drift without any manual effort. ",
    image: require("../../assets/images/olake-product-7.svg"),
  },
];

const OlakeFeatures = () => {
  const childRef = useRef();

  useEffect(() => {
    childRef.current.init();
  }, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <div className="olake-product-feature-box">
          <div className="olake-product-feature-heading reveal-from-top">
            Unlock the{" "}
            <span className="olake-product-feature-heading-type1">
              full potential
            </span>{" "}
            of MongoDB replication with OLake
          </div>
          <div className="olake-product-feature-list reveal-from-bottom">
            {features.map((feature) => {
              return (
                <div className="olake-product-feature">
                  <img
                    src={feature.image}
                    alt={feature.heading}
                    className="olake-product-feature-image"
                  />
                  <div className="olake-product-feature-inner-heading">
                    {feature.heading}
                  </div>
                  <div className="olake-product-feature-subheading">
                    {feature.subheading}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    />
  );
};

export default OlakeFeatures;
